'process i18n';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'midoffice/components/Button';
import Modal from 'react-bootstrap/Modal';

import gettext from 'airborne/gettext';

import {issueTicket} from './actions';
import {isModificationInProgress} from '../selectors';

export class TicketIssueModal extends React.Component {
    static propTypes = {
        uid: PropTypes.string.isRequired,
        onTicketIssue: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
        loading: PropTypes.bool,
    };

    handleTicketing = ()=> {
        const {onTicketIssue, uid} = this.props;
        onTicketIssue(uid, 'aft');
    };

    render() {
        const {onHide, loading} = this.props;

        return (
            <Modal onHide={onHide} show>
                <Modal.Header closeButton>
                    <Modal.Title>Please confirm to proceed with issuing the ticket</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button bsStyle="danger"
                        onClick={onHide}>Discard</Button>
                    <Button bsStyle="primary"
                        progress={loading}
                        disabled={loading}
                        onClick={this.handleTicketing}>{gettext('Confirm')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        loading: isModificationInProgress(state, props.uid),
    };
}

const mapDispatchToProps = {onTicketIssue: issueTicket};

export default connect(mapStateToProps, mapDispatchToProps)(TicketIssueModal);
