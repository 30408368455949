import makeModals from 'airborne/bookings2/ModalContainer';

import {CarsDestinationsModal, HotelsDestinationsModal} from 'airborne/homepage2/DestinationsModal';
import NoRatesModal from 'airborne/search2/NoRatesModal';
import CarsRateDetailsModal from 'airborne/search_cars/RateDetailsModal';
import SearchModal from 'airborne/homepage2/SearchModal';
import RestrictedDestinationModal from 'airborne/homepage2/RestrictedDestinationModal';
import TSPMTravelerModal from 'airborne/homepage2/tspm/TravelerModal';
import TSPMMultyTravelerModal from 'airborne/homepage2/tspm/MultyTravelerModal';
import TSPMCompanyModal from 'airborne/homepage2/tspm/CompanyModal';
import NoAcceptedCardsModal from 'airborne/checkout2/NoAcceptedCardsModal';
import {BookingModal} from 'airborne/bookings2/lazyloader';
import {ControlledEmailOffersModal} from 'airborne/search2/EmailOffersModal';
import PNRSearchModal from './PNRSearchModal';
import BookingRateDetailsModal from 'midoffice/bookings/BookingRateDetailsModal';
import BookingModificationModal3 from 'midoffice/bookings/modify/BookingModificationModal';
import BookingCancellationModal3 from 'midoffice/bookings/modify/BookingCancellationModal';
import BookingCancellationModal from 'midoffice/car-bookings/modify/BookingCancellationModal';
import UpdatePaymentInfoModal from 'midoffice/bookings/modify/UpdatePaymentInfoModal';
import CounterofferModal from 'midoffice/bookings/modify/CounterofferModal';
import BookingResolveModal from 'midoffice/bookings/modify/BookingResolveModal';
import BookingNoteModal from 'midoffice/bookings/modify/BookingNoteModal';
import AirModifyModal from 'midoffice/air-bookings/modify/AirModifyModal';
import WrongBedTypeModal from 'airborne/search2/WrongBedTypeModal/index';
import airExternalModifyModal from 'midoffice/external-bookings/AirExternalModifyModal';
import {VisaFormModal as CheckoutVisaForm} from 'airborne/checkout_air/TravelDocumentation/VisaForm';
import {WarnModalWithText} from 'midoffice/components/companies/WarnModalWithText';
import ReportProcessingModal from 'midoffice/components/reporting/ReportProcessingModal';
import TicketingModal from "midoffice/air-bookings/modify/TicketingModal";


const ModalContainer = makeModals({
    destinations: HotelsDestinationsModal,
    carsDestinations: CarsDestinationsModal,
    error: SearchModal,
    search: SearchModal,
    excluded: SearchModal,
    norates: NoRatesModal,
    pnrSearch: PNRSearchModal,
    warningWithText: WarnModalWithText,
    tspmTraveler: TSPMTravelerModal,
    tspmMultyTraveler: TSPMMultyTravelerModal,
    tspmCompany: TSPMCompanyModal,
    noAcceptedCards: NoAcceptedCardsModal,
    booking: BookingModal,
    emailOffer: ControlledEmailOffersModal,
    rateDetails: BookingRateDetailsModal,
    bookingModification3: BookingModificationModal3,
    bookingCancellation3: BookingCancellationModal3,
    bookingCancellation: BookingCancellationModal,
    updatePaymentInfo: UpdatePaymentInfoModal,
    counterofferModal: CounterofferModal,
    bookingResolveModal: BookingResolveModal,
    bookingNoteModal: BookingNoteModal,
    airModify: AirModifyModal,
    airTicketing: TicketingModal,
    airExternalModify: airExternalModifyModal,
    carsRateDetails: CarsRateDetailsModal,
    wrongBedType: WrongBedTypeModal,
    restrictedDestination: RestrictedDestinationModal,
    checkoutVisaForm: CheckoutVisaForm,
    reportProcessing: ReportProcessingModal,
});

export default ModalContainer;
